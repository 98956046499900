import React from "react"
import Layout from "../components/layout"

const NotFound = () => (
  <Layout>
    <section title="Not Found">
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </section>
  </Layout>
)

export default NotFound
